<template>
  <div>
    <b-modal id="modal-inventory-multiple-edit" size="xl" title="Pilih Obat" hide-footer>
      <div class="row">
        <div class="col-md-9">
          <Table
            purpose="modal"
            modalPurpose="modal"
            :isHospitalTransaction="isHospitalTransaction"
            :selectedItem="selectedItem"
            :checkbox="checkbox"
            :isSortcut="false"
            :isAbjad="true"
            @chosenItem="chosenItem"
            @packageMedicine="packageMedicine"
            :secondPurpose="secondPurpose"
          />
        </div>
        <div class="col-md-3">
          <b-table
            striped
            hover
            :items="selectedItem"
            :fields="fields"
            responsive
          >
            <!-- :style="{ width: field.key === 'actions' ? purpose == 'master' ? '17%' : '' : '' , width: field.key === 'responsible_doctor' ? '10%' : '' }" -->
            <template #table-colgroup="scope">
              <col
                v-for="field in scope.fields"
                :key="field.key"
                :style="{
                  width:
                    field.key === 'actions'
                      ? purpose == 'master'
                        ? '17%'
                        : ''
                      : '',
                  width: field.key == 'generic_name' ? '15%' : '',
                }"
              />
            </template>
            <template #cell(name)="data">
              <span>{{ data.item.name }}</span>
            </template>
            <template #cell(actions)="data">
              <b-button
                size="sm"
                class="btn-danger mr-1"
                style="padding: 5px !important"
                v-b-tooltip.hover
                title="Hapus"
                @click="deleteData(data.item.id)"
                ><i class="fas fa-trash px-0"></i
              ></b-button>
            </template>
          </b-table>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import Table from "@/component/inventory/TableMultiple.vue";
import ApiService from "@/core/services/api.service.js";
import module from "@/core/modules/CrudModule.js";

export default {
  props: {
    selectedItem: Array,
    checkbox: Boolean,
    isHospitalTransaction: Boolean,
    isSortcut: Boolean,
    secondPurpose: {
      type: String,
      default: "listing",
    },
  },

  data() {
    return {
      selectedData: [],
      // fields
      fields: [
        {
          key: "name",
          label: "Inventori dipilih",
          sortable: true,
          thClass: "nowrap",
          tdClass: "p-1 nowrap",
        },
        {
          key: "actions",
          label: "",
          thClass: "nowrap",
          tdClass: "p-1",
        },
      ],
    };
  },

  components: {
    Table,
  },

  methods: {
    async deleteData(id) {
      console.log(id);
      let response = await module.delete(`inventory-detail-stock-opname/${id}`);

      if (response) {
        this.$emit("deleteInventory");
      }
      // let a;
      // for (a = 0; a < this.selectedData.length; a++) {
      //   if (this.selectedItem[a].id == id) {
      //     this.selectedItem.splice(a, 1);
      //     console.log("hasil", this.selectedItem);
      //   }
      // }
      // // this.selectedItem = this.selectedItem
      // this.$root.$emit("updateMedicine",this.selectedItem)
      // this.$emit("packageMedicine", this.selectedItem);
    },

    chosenItem(value) {
      this.$emit("chosenItem", value);
    },

    packageMedicine(datas) {
      this.$emit("packageMedicine", datas);
      this.selectedItem = datas;
    },
  },
  mounted() {},
};
</script>

<style>
</style>
import { render, staticRenderFns } from "./InventoryStockOpnameEdit.vue?vue&type=template&id=380e3794&"
import script from "./InventoryStockOpnameEdit.vue?vue&type=script&lang=js&"
export * from "./InventoryStockOpnameEdit.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports